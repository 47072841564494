import clsx from "clsx";
import { Button } from "components/Button";

const Form = () => {
  const inputClass = clsx(
    `border-[1.5px] border-solid border-[#707070] rounded-full shadow-md`,
    // Padding
    `px-6 py-4`,
    // Font
    `font-sans text-primary`,
  );

  const labelClass = clsx(`font-sans uppercase text-primary`);

  return (
    <form
      className="flex flex-col gap-4 md:gap-6 relative"
      action="/api/donate"
    >
      {/* Name: name */}
      <fieldset className="flex flex-col gap-2">
        <label className={labelClass} htmlFor="name">
          Name*
        </label>
        <input
          className={inputClass}
          type="text"
          name="name"
          id="name"
          placeholder="First and Last"
          required
        />
      </fieldset>
      {/* Organization: organization */}
      <fieldset className="flex flex-col gap-2">
        <label className={labelClass} htmlFor="organization">
          Organization
        </label>
        <select
          className={inputClass}
          name="organization"
          id="organization"
          placeholder="Organization"
        >
          {/* Please Select */}
          <option value="No Organization Selected">Please Select</option>
          {/* American Cancer Society */}
          <option value="American Cancer Society">
            American Cancer Society
          </option>
          {/* Beyer Cares General Fund */}
          <option value="Beyer Cares General Fund">
            Beyer Cares General Fund
          </option>
          {/* All of the Above */}
          <option value="All of the Above">All of the Above</option>
        </select>
      </fieldset>

      {/*
        Donation Options: donation_type
        - One Time
        - Monthly
        - Yearly
      */}
      <fieldset className="flex flex-col gap-2">
        <label className={labelClass} htmlFor="donation_type">
          Donation Options*
        </label>
        <select className={inputClass} name="donation_type" id="donation_type">
          <option value="one_time">One Time</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </select>
      </fieldset>
      {/* Amount: amount */}
      <fieldset className="flex flex-col gap-2">
        <label className={labelClass} htmlFor="amount">
          Amount*
        </label>
        <input
          className={inputClass}
          type="number"
          name="amount"
          id="amount"
          placeholder="Amount"
          // Number keyboard
          inputMode="numeric"
          // Only accept positive numbers
          min="1"
          required
        />
      </fieldset>
      {/* In Memory of: in_memory_of */}
      <fieldset className="flex flex-col gap-2">
        <label className={labelClass} htmlFor="in_memory_of">
          In Memory of
        </label>
        <input
          className={inputClass}
          type="text"
          name="in_memory_of"
          id="in_memory_of"
          placeholder="In Memory of"
        />
      </fieldset>
      <Button variant={`secondary`} className={`w-fit`} type={`submit`}>
        Donate
      </Button>
    </form>
  );
};

export default Form;
