import { gql } from "@apollo/client";
import { useSnapCarousel } from "react-snap-carousel";

import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { FlexibleContentBlocksTestimonials, Node, Testimonial } from "graphql";
import { FeaturedImage } from "components/FeaturedImage";
import clsx from "clsx";

interface TestimonialsProps extends FlexibleContentBlocksTestimonials {
  className?: string;
}

const Testimonials = ({
  className,
  title,
  testimonialItems: items,
}: TestimonialsProps) => {
  const {
    scrollRef,
    pages,
    activePageIndex,
    prev,
    next,
    goTo,
    snapPointIndexes,
  } = useSnapCarousel();

  return (
    <div className={`bg-brand-gray`}>
      <div className={`container relative mx-auto max-w-6xl px-4 md:px-0`}>
        <div
          className={`relative z-10 flex flex-row overflow-x-scroll no-scrollbar`}
          ref={scrollRef}
        >
          {items &&
            items?.nodes.map((item, index) => {
              const { id, title, content, testimonialOptions, featuredImage } =
                item as Testimonial;

              const { descriptor } = testimonialOptions || {};
              return (
                <div
                  key={item.id}
                  className={`relative grid grid-cols-1 gap-4 md:grid-cols-2 min-w-full`}
                  style={{
                    scrollSnapAlign: "center",
                  }}
                >
                  <div
                    className={`relative flex flex-col justify-center items-center h-full w-full`}
                  >
                    <FeaturedImage image={featuredImage?.node} />
                  </div>
                  <div className={`p-4`}>
                    {content && (
                      <div
                        className={`mb-4 text-left font-serif md:text-2xl`}
                        dangerouslySetInnerHTML={{ __html: content }}
                      />
                    )}
                    {title && (
                      <h2
                        className={`text-left font-accent text-3xl font-bold text-secondary`}
                      >
                        - {title}
                      </h2>
                    )}
                    <p className={`text-left font-sans text-xs`}>
                      {descriptor}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          className={`relative md:absolute bottom-4 right-2 z-20 flex w-fit flex-row gap-4 mx-auto pt-4`}
        >
          {items &&
            items?.nodes.map((item, index) => {
              return (
                <button
                  key={`navigation-${item.id}`}
                  className={clsx(
                    `h-6 w-6 rounded-full border-primary border-[1px]`,
                    {
                      "bg-primary nav-button": activePageIndex === index,
                      "bg-white": activePageIndex !== index,
                    },
                  )}
                  aria-label={`Go to slide ${index}`}
                  name={`Go to slide ${index}`}
                  onClick={() => goTo(index)}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

Testimonials.fragments = {
  entry: gql`
    fragment TestimonialsFragment on FlexibleContentBlocksTestimonials {
      __typename
      title
      testimonialItems {
        nodes {
          ... on Testimonial {
            id
            title
            content
            featuredImage {
              node {
                ...MediaItemFragment
              }
            }
            testimonialOptions {
              descriptor
            }
          }
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
