import { gql } from "@apollo/client";
import clsx from "clsx";
import { FlexibleContentBlocksDonationForm } from "graphql";
import Form from "./Fragments/Form";
import DonateRibbon from "public/assets/donate-ribbon.svg";
import Counter from "components/Impact/Fragments/Counter";
import { useRef } from "react";
import { useInView } from "framer-motion";

interface DonationFormProps extends FlexibleContentBlocksDonationForm {
  className?: string;
}

const DonationForm = ({
  className,
  donationTitle: title,
  caption,
  impactItems,
}: DonationFormProps) => {
  const ref = useRef(null);

  const isInView = useInView(ref, {
    once: true,
  });

  return (
    <section
      className={clsx(`relative pt-12 pb-24 overflow-x-hidden`, {
        className: className,
      })}
    >
      {/* Donate Ribbon */}
      <div className="absolute top-[100px] md:top-0 w-[110%] left-[-5%] h-fit">
        <DonateRibbon />
      </div>
      <div className="absolute top-1/2 md:hidden w-[110%] left-[-5%] h-fit">
        <DonateRibbon />
      </div>
      <div className={clsx(`container max-w-7xl mx-auto relative`)}>
        <div className={clsx(`grid grid-cols-1 md:grid-cols-2 px-4`)}>
          {/* Form */}
          <div className={clsx(`flex flex-col`)}>
            {title && (
              <h1 className="text-4xl md:text-8xl font-accent text-secondary">
                {title}
              </h1>
            )}
            {caption && (
              <p className="text-lg font-sans text-primary max-w-sm">
                {caption}
              </p>
            )}
            <Form />
          </div>
          {/* Impact Items */}
          <div
            className={clsx(
              `flex flex-col justify-center px-4 py-8 md:px-12 md:py-0`,
            )}
            ref={ref}
          >
            <h2 className="">
              <span
                className={`text-2xl text-primary uppercase font-sans font-bold`}
              >
                Your
              </span>
              <br />
              <span
                className={clsx(
                  `text-4xl md:text-8xl font-accent text-secondary`,
                )}
              >
                Impact
              </span>
            </h2>
            <div className={`grid grid-cols-2 lg:grid-cols-6 gap-6`}>
              {impactItems &&
                impactItems.map((item, index) => {
                  const isLastTwo = index > 2;
                  const isLast = index === impactItems.length - 1;
                  return (
                    item?.number && (
                      <div
                        className={`text-center flex flex-col items-center justify-start px-4 ${
                          isLast && `col-span-2`
                        } ${isLastTwo ? `lg:col-span-3` : `lg:col-span-2`}`}
                        key={`impact-item-${item?.number}`}
                      >
                        <div
                          className={`flex flex-row items-center justify-center text-secondary font-accent`}
                        >
                          <span className={`text-4xl`}>{item?.prefix}</span>
                          <Counter value={item?.number} isInView={isInView} />
                        </div>
                        <div
                          className={`text-primary font-sans text-sm text-center`}
                          dangerouslySetInnerHTML={{
                            __html: item?.caption ?? ``,
                          }}
                        />
                      </div>
                    )
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DonationForm;

DonationForm.fragments = {
  entry: gql`
    fragment DonationFormFragment on FlexibleContentBlocksDonationForm {
      __typename
      donationTitle
      caption
      impactItems {
        prefix
        number
        caption
      }
    }
  `,
};
