import { gql } from "@apollo/client";
import { AcfLink, MenuItem } from "graphql";
import Link from "next/link";
import { AnimatePresence, m, LazyMotion } from "framer-motion";
import { itemVariants, sideVariants } from "./Fragments/variants";
import { MenuButton } from "./Fragments/MenuButton";
import { Button } from "components/Button";
import clsx from "clsx";
const loadFeatures = () =>
  import("utilities/framerFeatures.js").then((res) => res.default);

interface NavigationMenuProps {
  menuItems: MenuItem[] | undefined;
  className?: string;
  type?: "primary" | "secondary";
  isMobile?: boolean;
  isOpen?: boolean;
  toggleOpen?: () => void;
  cta?: AcfLink;
  cta2?: AcfLink;
}

const NavigationMenu = ({
  menuItems,
  className,
  type = "primary",
  isMobile,
  isOpen,
  toggleOpen,
  cta,
  cta2,
}: NavigationMenuProps) => {
  if (!menuItems) {
    return null;
  }

  return (
    <>
      <LazyMotion features={loadFeatures}>
        <AnimatePresence>
          <MenuButton
            className={clsx(`md:hidden`)}
            isOpen={isOpen}
            onClick={toggleOpen}
            lineProps={{ strokeLinecap: "round" }}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
            width="24"
            height="24"
            strokeWidth="6"
            color="#363636"
          />
          {isOpen && (
            <m.aside
              initial={{ height: 0 }}
              animate={{
                height: `100%`,
              }}
              exit={{
                height: 0,
                transition: { delay: 0.7, duration: 0.3 },
              }}
              className={`z-50 grid h-full items-center absolute top-full left-0 right-0`}
            >
              <m.nav
                className={`bottom-100 font-heading left-4 z-40 grid place-items-center gap-8 text-base md:flex-row md:gap-0 bg-white py-6`}
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                {menuItems.map(({ path, label, menuOptions }) => {
                  if (
                    menuOptions?.isButton &&
                    menuOptions &&
                    menuOptions?.type
                  ) {
                    return (
                      <Button
                        key={path}
                        variant={menuOptions?.type[0] ?? "primary"}
                        href={label === "Contact Us" ? `#contact` : path ?? ``}
                      >
                        {label}
                      </Button>
                    );
                  }
                  return (
                    <m.div
                      key={path}
                      whileHover={{ scale: 1.1 }}
                      variants={itemVariants}
                    >
                      <Link
                        href={path ?? "/"}
                        className={`text-center hover:text-gray-900 ${
                          type === "primary" ? "text-gray-900" : "text-gray-600"
                        }`}
                        scroll={false}
                      >
                        {label}
                      </Link>
                    </m.div>
                  );
                })}
              </m.nav>
            </m.aside>
          )}
        </AnimatePresence>
      </LazyMotion>
      <nav className="font-heading hidden flex-col items-center gap-6 pr-12 text-base md:relative md:flex md:flex-row">
        {menuItems.map(({ path, label, menuOptions }) => {
          if (menuOptions?.isButton && menuOptions && menuOptions?.type) {
            return (
              <Button
                key={path}
                variant={menuOptions?.type[0] ?? "primary"}
                href={label === "Contact Us" ? `#contact` : path ?? ``}
              >
                {label}
              </Button>
            );
          }
          return (
            <Link
              key={path}
              href={path ?? "/"}
              className={`hover:text-secondary ${
                type === "primary" ? "text-white" : "text-primary"
              }`}
            >
              {label}
            </Link>
          );
        })}
        {/* {cta ? (
          <Button
            variant={`primary-outline`}
            className={`ml-4`}
            href={cta.url ?? ``}
          >
            {cta.title}
          </Button>
        ) : null}
        <Button variant={`primary`} className={`ml-4`} href={`/donate`}>
          Donate
        </Button> */}
      </nav>
    </>
  );
};

export default NavigationMenu;

NavigationMenu.fragments = {
  entry: gql`
    fragment NavigationMenuItemFragment on MenuItem {
      id
      path
      label
      parentId
      cssClasses
      menuOptions {
        isButton
        type
      }
      menu {
        node {
          name
        }
      }
    }
  `,
};
