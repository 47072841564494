import { gql } from "@apollo/client";
import clsx from "clsx";
import { FeaturedImage } from "components/FeaturedImage";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { FlexibleContentBlocksIconList, MediaItem } from "graphql";

interface IconListProps extends FlexibleContentBlocksIconList {
  className?: string;
}

interface IconProps {
  icon: MediaItem;
  title: string;
  caption: string;
}

const IconList = ({
  className,
  icons,
  iconListTitle: title,
}: IconListProps) => {
  const hasTitle = title && title !== "";
  const hasItems = icons && icons.length > 0;
  return (
    <div
      className={clsx(
        `relative container max-w-7xl mx-auto py-6 md:py-12 flex flex-col gap-6 px-4`,
        className,
      )}
    >
      {hasTitle && (
        <div
          className={`flex flex-col gap-4`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {hasItems && (
        <div
          className={`grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-6 md:gap-6`}
        >
          {icons.map((icon, index) => (
            <Icon
              key={index}
              icon={icon?.icon?.node as MediaItem}
              title={icon?.title as string}
              caption={icon?.caption as string}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Icon = ({ icon, title, caption }: IconProps) => {
  return (
    <div className={`flex flex-col items-center justify-center gap-4`}>
      {icon && (
        <FeaturedImage image={icon} className={`w-16 h-16 md:w-24 md:h-24`} />
      )}
      {title && (
        <h2 className={`text-2xl font-accent text-secondary md:text-4xl`}>
          {title}
        </h2>
      )}
      {caption && (
        <div
          className={`text-sm font-sans text-center md:text-md`}
          dangerouslySetInnerHTML={{ __html: caption }}
        />
      )}
    </div>
  );
};

export default IconList;

IconList.fragments = {
  entry: gql`
    fragment IconListFragment on FlexibleContentBlocksIconList {
      iconListTitle
      icons {
        icon {
          node {
            ...MediaItemFragment
          }
        }
        title
        caption
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
