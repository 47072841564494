import { gql } from "@apollo/client";
import clsx from "clsx";
import { Button } from "components/Button";
import { FlexibleContentBlocksTextBlock } from "graphql";

interface TextBlockProps extends FlexibleContentBlocksTextBlock {
  className?: string;
}

const TextBlock = ({
  className,
  textBlockTitle: title,
  content,
  ctas,
}: TextBlockProps) => {
  return (
    <div
      className={clsx(
        className,
        `container mx-auto flex max-w-5xl flex-col gap-4 px-4 py-6 md:py-12`,
      )}
    >
      {title ? (
        <h2
          className={`mx-auto w-fit border-b-2 border-primary/50 pb-4 text-center font-sans text-2xl uppercase`}
        >
          {title}
        </h2>
      ) : null}
      {content ? (
        <div
          className={`text-center font-serif text-md md:text-lg text-primary`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : null}
      {ctas ? (
        <div className={`flex flex-col items-center gap-4`}>
          {ctas.map(({ link, type }: any, index) => {
            return (
              <Button
                key={index}
                className={`btn btn-primary`}
                href={link.url}
                variant={type[0] ?? `primary-outline`}
              >
                {link.title}
              </Button>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default TextBlock;

TextBlock.fragments = {
  entry: gql`
    fragment TextBlockFragment on FlexibleContentBlocksTextBlock {
      __typename
      textBlockTitle
      content
      ctas {
        link {
          title
          url
          target
        }
        type
      }
    }
  `,
};
