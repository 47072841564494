import Link from "next/link";
import clsx from "clsx";

interface ButtonProps {
  href?: string;
  children: React.ReactNode;
  className?: string;
  variant?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  target?: string;
}

const styleLoader = (type: string) => {
  let defaultStyle = `flex items-center justify-center text-center px-4 py-2 rounded-lg font-bold min-w-[150px] font-sans`;
  return clsx(
    defaultStyle,
    `transition duration-300 ease-in-out`,
    // border inset
    {
      "bg-primary text-white hover:bg-white hover:text-primary border-2 border-primary":
        type === "primary",
      "bg-white text-primary hover:bg-primary hover:text-white border-2 border-primary":
        type === "primary-outline",
      "bg-secondary text-white hover:bg-white hover:text-secondary":
        type === "secondary",
      "bg-white text-secondary hover:bg-secondary hover:text-white":
        type === "secondary-outline",
      "bg-tertiary text-white hover:bg-white hover:text-tertiary":
        type === "tertiary",
      "bg-white text-tertiary hover:bg-tertiary hover:text-white":
        type === "tertiary-outline",
      "bg-quaternary text-white hover:bg-white hover:text-quaternary":
        type === "quaternary",
      "bg-white text-quaternary hover:bg-quaternary hover:text-white":
        type === "quaternary-outline",
      "bg-alert text-white hover:bg-white hover:text-alert": type === "alert",
      "bg-donate text-white hover:bg-white hover:text-donate":
        type === "donate",
      "bg-white text-donate hover:bg-donate hover:text-white":
        type === "donate-white",
    },
  );
};

const Button: React.FC<ButtonProps> = ({
  href,
  children,
  className,
  variant = "primary",
  disabled,
  type = "button",
  target = "_self",
  ...props
}) => {
  if (href && variant !== "donate" && variant !== "donate-white") {
    return (
      <Link
        className={`${styleLoader(variant)} ${className}`}
        role="button"
        href={href}
        target={target}
        {...props}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled}
      className={`${styleLoader(variant)} ${className}`}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
