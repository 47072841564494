import { Form } from "components/Form";
import Image from "next/image";
import { AcfGoogleMap } from "graphql";
import clsx from "clsx";
import { Button } from "components/Button";
export interface FooterProps {
  address?: AcfGoogleMap;
  isAlt?: boolean;
}

const Footer = ({
  address,
  isAlt,
}: // address,
FooterProps) => {
  const {
    streetAddress,
    city,
    state,
    postCode,
    placeId,
    streetNumber,
    streetName,
    stateShort,
  } = address || {};

  let googleLink =
    address && `https://www.google.com/maps/place/?q=place_id:${placeId}`;

  const year = new Date().getFullYear();

  return (
    <footer
      className={clsx(`bg-[#f3f3f2] pt-12 text-gray-100`, {
        "mt-12": isAlt,
      })}
    >
      <div
        className={`container mx-auto max-w-7xl flex flex-col flex-wrap px-4 py-8 md:flex-row md:justify-between`}
      >
        {/* Form */}
        <div
          id="contact"
          className={`flex flex-row flex-wrap items-center justify-center md:justify-start`}
        >
          <Form form={1} formTitle={"Contact Us"} />
        </div>
        {/* Menu */}
        <div
          className={`flex flex-col items-center md:items-end justify-center md:justify-start gap-6`}
        >
          <Image
            src={"/logo-alt.png"}
            alt={"Logo Alt"}
            width={266}
            height={89}
            className={`w-fit h-auto`}
          />
          <a
            href={googleLink}
            target="_blank"
            rel={`noopener noreferrer`}
            className={`flex flex-col text-primary font-sans font-bold md:text-right`}
          >
            <span>{`${streetNumber} ${streetName}`}</span>
            <span>{`${city}, ${stateShort} ${postCode}`}</span>
          </a>
          <hr className={`w-full border-primary`} />
          <Button
            variant={`primary-outline`}
            href={`https://beyerplumbing.com/`}
            className={`w-fit`}
          >
            Beyer Plumbing Co.
          </Button>
          <Button
            variant={`primary-outline`}
            href={`https://beyerairconditioningheating.com/`}
            className={`w-fit`}
          >
            Beyer Air Conditioning & Heating
          </Button>
          <Button
            variant={`primary-outline`}
            href={`https://bmltd.com/`}
            className={`w-fit`}
          >
            Beyer Mechanical
          </Button>
        </div>
      </div>
      <div className={`bg-primary`}>
        <div
          className={`container mx-auto max-w-7xl py-8 px-2 flex flex-col items-center font-sans`}
        >
          <p>{`Copyright© ${year} Beyer Cares Foundation. All Rights Reserved.`}</p>
          <p>Beyer Cares Foundation is registered as a 501(c)3 non-profit.</p>
          <div>
            <a href="https://noisytrumpet.com" target="_blank" rel="noreferrer">
              Site by Noisy Trumpet | Digital & Public Relations
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
