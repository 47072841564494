import { gql } from "@apollo/client";
import clsx from "clsx";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { FlexibleContentBlocksImpactGrid } from "graphql";
import Counter from "./Fragments/Counter";
import { FeaturedImage } from "components/FeaturedImage";
import { useRef } from "react";
import { useInView } from "framer-motion";
import JustRibbon from "public/assets/just-ribbon.svg";
interface ImpactProps extends FlexibleContentBlocksImpactGrid {
  className?: string;
}

const Impact = ({ className, impactTitle: title, items, id }: ImpactProps) => {
  const ref = useRef(null);

  const isInView = useInView(ref, {
    once: true,
  });

  return (
    <div
      className={`relative h-full overflow-x-hidden overflow-y-hidden`}
      id={id ?? ``}
    >
      <div
        className={clsx(
          `absolute -left-[10%] z-20 min-w-[120%] opacity-[30%]`,
          `top-0`,
          // roatate 45 degrees
          `rotate-[15deg] transform`,
          // Tablet
          // `md:top-[10%]`,
          // Large Tablet
          // `lg:bottom-1/4`,
          // `lg:-top-1/2`,
          // Desktop
          // `xl:-top-3/4`,
        )}
      >
        <JustRibbon className={`w-full  translate-y-[-37.5%]`} />
      </div>
      <div
        className={clsx(
          className,
          `container mx-auto flex max-w-7xl flex-col gap-4 px-4 py-6 md:gap-8 md:pt-18 md:pb-12`,
        )}
        ref={ref}
      >
        {title ? (
          <div
            className={`z-40 w-fit border-b-2 border-b-gray-300 pb-6 text-left font-sans text-3xl`}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        ) : null}

        <div
          className={`z-30 grid grid-cols-2 gap-4 py-12 md:grid-cols-2 md:gap-28 lg:grid-cols-4 items-start`}
        >
          {items &&
            items.map(({ number, label, icon, isMoneyAmount }: any, i) => {
              return (
                <div
                  key={`impact-item-${i}`}
                  className={`flex flex-col items-center justify-center gap-2`}
                >
                  {icon?.node ? (
                    <FeaturedImage
                      image={icon?.node}
                      className={`h-16 w-16 md:h-24 md:w-24`}
                    />
                  ) : null}
                  {number ? (
                    <>
                      {isMoneyAmount ? (
                        <div className={`flex flex-row gap-2`}>
                          <p
                            className={`leading-none pt-[7px] mr-[-5px] text-3xl font-accent font-bold text-secondary not-italic`}
                          >
                            $
                          </p>
                          <Counter value={number} isInView={isInView} />
                        </div>
                      ) : (
                        <Counter value={number} isInView={isInView} />
                      )}
                    </>
                  ) : null}
                  {label ? (
                    <span className={`text-center font-sans text-primary`}>
                      {label}
                    </span>
                  ) : null}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Impact;

Impact.fragments = {
  entry: gql`
    fragment ImpactFragment on FlexibleContentBlocksImpactGrid {
      impactTitle
      id
      items {
        number
        label
        isMoneyAmount
        icon {
          node {
            ...MediaItemFragment
          }
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};

export const IMPACT_FRAGMENT = gql`
  fragment ImpactFragment on FlexibleContentBlocksImpactGrid {
    impactTitle
    items {
      number
      label
      isMoneyAmount
      icon {
        node {
          ...MediaItemFragment
        }
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;
