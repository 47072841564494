// Hero: (Non dynamic import) Above the fold content
import {
  Hero,
  ImageGrid,
  Features,
  Impact,
  TextBlock,
  TextImage,
  Testimonials,
  EventList,
  PhotoGallery,
  IconList,
  CTA,
  DonationForm,
  Form,
} from "components";

import { FORM_BLOCK_FRAGMENT } from "components/Form/Form";

import {
  FlexibleContentBlocksHero,
  FlexibleContent,
  FlexibleContentBlocks_Layout,
  FlexibleContentBlocksImageGrid,
  FlexibleContentBlocksFeatures,
  FlexibleContentBlocksImpactGrid,
  FlexibleContentBlocksTextBlock,
  FlexibleContentBlocksTextImage,
  FlexibleContentBlocksTestimonials,
  FlexibleContentBlocksEventList,
  FlexibleContentBlocksPhotoGallery,
  FlexibleContentBlocksIconList,
  FlexibleContentBlocksCta,
  FlexibleContentBlocksDonationForm,
  FlexibleContentBlocksForm,
} from "graphql";
import { gql } from "@apollo/client";
import { useId } from "react";

interface BlocksProps {
  blocks: FlexibleContent[];
}

interface BlockProps {
  block: FlexibleContentBlocks_Layout;
}

const prefix = "FlexibleContentBlocks";

const Block = ({ block }: BlockProps) => {
  // @ts-ignore
  const { __typename } = block;

  const name = __typename && __typename.replace(prefix, "");

  switch (name) {
    // Hero
    case "Hero": {
      return <Hero {...(block as FlexibleContentBlocksHero)} />;
    }
    // Image Grid
    case "ImageGrid": {
      return <ImageGrid {...(block as FlexibleContentBlocksImageGrid)} />;
    }
    // Features
    case "Features": {
      return <Features {...(block as FlexibleContentBlocksFeatures)} />;
    }
    // Impact
    case "ImpactGrid": {
      return <Impact {...(block as FlexibleContentBlocksImpactGrid)} />;
    }
    // Text Block
    case "TextBlock": {
      return <TextBlock {...(block as FlexibleContentBlocksTextBlock)} />;
    }
    // Text Image
    case "TextImage": {
      return <TextImage {...(block as FlexibleContentBlocksTextImage)} />;
    }
    // Testimonials
    case "Testimonials": {
      return <Testimonials {...(block as FlexibleContentBlocksTestimonials)} />;
    }
    // Event List
    case "EventList": {
      return <EventList {...(block as FlexibleContentBlocksEventList)} />;
    }
    // Photo Gallery
    case "PhotoGallery": {
      return <PhotoGallery {...(block as FlexibleContentBlocksPhotoGallery)} />;
    }
    // Icon List
    case "IconList": {
      return <IconList {...(block as FlexibleContentBlocksIconList)} />;
    }
    // Cta
    case "Cta": {
      return <CTA {...(block as FlexibleContentBlocksCta)} />;
    }
    // Donation Form
    case "DonationForm": {
      return <DonationForm {...(block as FlexibleContentBlocksDonationForm)} />;
    }
    // Form
    case "Form": {
      return <Form {...(block as FlexibleContentBlocksForm)} />;
    }

    default: {
      return (
        <div className={`text-center font-sans text-2xl`}>
          {`Component ${name} under construction`}
        </div>
      );
    }
  }
};

const Blocks = ({ blocks = [] }: BlocksProps): JSX.Element => {
  return (
    <>
      {blocks &&
        blocks.map((block, index) => {
          const id = useId();
          return <Block block={block} key={`block-${index}-${id}`} />;
        })}
    </>
  );
};

export default Blocks;

Blocks.fragments = {
  entry: gql`
    fragment FlexibleFragment on FlexibleContent_Fields {
      blocks {
        ... on FlexibleContentBlocksHero {
          ...HeroFragment
        }
        ... on FlexibleContentBlocksImageGrid {
          ...ImageGridFragment
        }
        ... on FlexibleContentBlocksFeatures {
          ...FeaturesFragment
        }
        ... on FlexibleContentBlocksImpactGrid {
          ...ImpactFragment
        }
        ... on FlexibleContentBlocksTextBlock {
          ...TextBlockFragment
        }
        ... on FlexibleContentBlocksTextImage {
          ...TextImageFragment
        }
        ... on FlexibleContentBlocksTestimonials {
          ...TestimonialsFragment
        }
        ... on FlexibleContentBlocksEventList {
          ...EventListFragment
        }
        ... on FlexibleContentBlocksPhotoGallery {
          ...PhotoGalleryFragment
        }
        ... on FlexibleContentBlocksIconList {
          ...IconListFragment
        }
        ... on FlexibleContentBlocksCta {
          ...CTAFragment
        }
        ... on FlexibleContentBlocksDonationForm {
          ...DonationFormFragment
        }
        ... on FlexibleContentBlocksForm {
          ...FormBlockFragment
        }
      }
    }
    ${Hero.fragments.entry}
    ${ImageGrid.fragments.entry}
    ${Features.fragments.entry}
    ${Impact.fragments.entry}
    ${TextBlock.fragments.entry}
    ${TextImage.fragments.entry}
    ${Testimonials.fragments.entry}
    ${EventList.fragments.entry}
    ${PhotoGallery.fragments.entry}
    ${IconList.fragments.entry}
    ${CTA.fragments.entry}
    ${DonationForm.fragments.entry}
    ${FORM_BLOCK_FRAGMENT}
  `,
};
