import { gql } from "@apollo/client";
import clsx from "clsx";
import { FeaturedImage } from "components/FeaturedImage";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { FlexibleContentBlocksTextImage } from "graphql";

interface TextImageProps extends FlexibleContentBlocksTextImage {
  className?: string;
}

const TextImage = ({
  className,
  textImageTitle: title,
  body,
  bottomCaption,
  image,
  imageSide,
  smallTitle,
}: TextImageProps) => {
  const isRight = imageSide && imageSide[0] === "right";
  const isLeft = imageSide && imageSide[0] === "left";

  return (
    <div className={`container mx-auto max-w-7xl px-4 py-8 md:px-8 md:py-12`}>
      <div className={`grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-16`}>
        <div
          className={clsx(`flex flex-col justify-start gap-6`, {
            "md:order-1": isRight,
            "md:order-2": isLeft,
          })}
        >
          {smallTitle && (
            <span className={`font-sans text-xl uppercase`}>{smallTitle}</span>
          )}
          {title && (
            <div className={``} dangerouslySetInnerHTML={{ __html: title }} />
          )}
          {body && (
            <div
              className={`flex flex-col gap-4 font-sans`}
              dangerouslySetInnerHTML={{ __html: body }}
            />
          )}
        </div>
        <div
          className={clsx(`flex flex-col items-center justify-center`, {
            "md:order-2": isRight,
            "md:order-1": isLeft,
          })}
        >
          {image && image.node && (
            <FeaturedImage image={image.node} className={``} />
          )}
          {bottomCaption && (
            <div className={`flex flex-col gap-4 py-6`}>
              {bottomCaption.caption && (
                <h3 className={`text-center font-sans uppercase`}>
                  {bottomCaption.caption}
                </h3>
              )}
              {bottomCaption.images && bottomCaption.images.nodes && (
                <div className={`flex gap-4 flex-row md:gap-8 items-center`}>
                  {bottomCaption.images.nodes.map((image) => (
                    <div className={`flex-auto`} key={image.id}>
                      <FeaturedImage
                        image={image}
                        className={`flex flex-col items-center justify-center w-full max-h-[112px]`}
                        imgClassName={`w-auto h-auto max-h-[112px] max-w-full`}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TextImage;

TextImage.fragments = {
  entry: gql`
    fragment TextImageFragment on FlexibleContentBlocksTextImage {
      textImageTitle
      body
      smallTitle
      bottomCaption {
        caption
        images {
          nodes {
            ...MediaItemFragment
          }
        }
      }
      image {
        node {
          ...MediaItemFragment
        }
      }
      imageSide
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
