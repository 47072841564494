import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { FlexibleContentBlocksImageGrid } from "graphql";
import { gql } from "@apollo/client";
import { FeaturedImage } from "components/FeaturedImage";
import clsx from "clsx";
import { Button } from "components/Button";
import HeartGray from "public/assets/heart-gray.svg";
import HeartWhite from "public/assets/heart-white.svg";
interface ImageGridProps extends FlexibleContentBlocksImageGrid {
  className?: string;
}

const ImageGrid = ({
  className,
  logos,
  fieldTitle: title,
  variant,
  id,
}: ImageGridProps) => {
  const hasTitle = title && title !== null && title !== "";
  const hasLogos = logos && logos !== null;
  let isPrimary = variant && variant[0] === "primary";
  let isSecondary = variant && variant[0] === "secondary";

  return (
    <div
      className={clsx(`relative gap-4 px-4`, className, {
        "bg-primary py-8 text-white md:py-10": isSecondary,
        "container mx-auto flex max-w-5xl flex-col": isPrimary,
      })}
      id={id ?? ``}
    >
      {isSecondary ? (
        <>
          <HeartGray className="absolute -top-8 left-[10%] h-20 w-20" />
          <HeartGray className="absolute -top-8 right-[10%] h-20 w-20 -scale-x-100" />
        </>
      ) : null}
      <div
        className={clsx({
          "flex flex-col gap-6": isPrimary,
          "container mx-auto flex max-w-5xl flex-col py-4 md:py-0": isSecondary,
        })}
      >
        {hasTitle ? (
          <div
            className={clsx(`text-center font-sans text-3xl uppercase`, {
              "text-white": isSecondary,
              "text-primary": isPrimary,
            })}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </div>
        ) : null}
        {hasLogos ? (
          <div
            className={clsx(
              `gap-4 border-2 border-primary px-2 py-2 md:py-8  md:px-12`,
              {
                "flex flex-row items-center justify-between rounded-lg md:rounded-full ":
                  isPrimary,
                "relative flex h-full flex-col items-stretch justify-center md:flex-row":
                  isSecondary,
              },
            )}
          >
            {logos.map(({ image, link }: any, i) => {
              const logoId = `image-grid-logo-${i}-${image?.node?.id}`;

              const isNotLast = i !== logos.length - 1;

              if (isSecondary) {
                return (
                  <div
                    key={logoId}
                    className={clsx(
                      `relative flex h-full flex-1 flex-col items-center justify-center gap-4 px-8 py-8 md:px-12 md:py-0`,
                      {},
                    )}
                  >
                    <FeaturedImage
                      image={image?.node}
                      className={`flex h-auto min-h-[100px] w-full flex-col items-center justify-center`}
                      imgClassName="object-contain w-20 h-auto mx-auto"
                    />
                    <Button
                      className={`mt-4`}
                      variant={`primary-outline`}
                      href={link?.url}
                    >
                      {link?.title}
                    </Button>
                    {isNotLast && (
                      <div
                        className={clsx(
                          `absolute bottom-0 right-0 h-[1px] w-full bg-white/50 md:top-0 md:h-full md:w-[1px]`,
                        )}
                      />
                    )}
                  </div>
                );
              }

              return (
                <a
                  key={logoId}
                  href={link?.url}
                  target={link?.target}
                  className={`flex h-fit flex-col items-center justify-center`}
                  aria-label={link?.title}
                >
                  <FeaturedImage
                    image={image?.node}
                    className={`h-auto w-20 object-contain md:w-40`}
                  />
                </a>
              );
            })}
          </div>
        ) : null}
      </div>
      {isSecondary ? (
        <>
          <HeartWhite className="absolute -bottom-8 left-1/4 h-20 w-20 -scale-x-100" />
          <HeartWhite className="absolute -bottom-8 right-1/4 h-20 w-20" />
        </>
      ) : null}
    </div>
  );
};

export default ImageGrid;

ImageGrid.fragments = {
  entry: gql`
    fragment ImageGridFragment on FlexibleContentBlocksImageGrid {
      __typename
      fieldTitle
      id
      variant
      logos {
        image {
          node {
            ...MediaItemFragment
          }
        }
        link {
          title
          url
          target
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
