import Number from "./Number";
import { useSpring } from "framer-motion";
import { useEffect } from "react";

const fontSize = 30;
const padding = 15;
const height = fontSize + padding;

const Digit = ({
  place,
  value,
  isInView,
}: {
  place: number;
  value: number;
  isInView: boolean;
}) => {
  let valueRoundedToPlace = Math.floor(value / place) % 10;
  let animatedValue = useSpring(isInView ? valueRoundedToPlace : 0);

  useEffect(() => {
    if (isInView) {
      animatedValue.set(valueRoundedToPlace);
    }
  }, [animatedValue, valueRoundedToPlace, isInView]);

  return (
    <div style={{ height }} className="relative w-[0.5ch] tabular-nums">
      {[...Array(10).keys()].map((i) => (
        <Number
          key={i}
          mv={animatedValue}
          place={place}
          number={i}
          isInView={isInView}
        />
      ))}
    </div>
  );
};

export default Digit;
