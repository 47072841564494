import { gql } from "@apollo/client";
import clsx from "clsx";
import { Button } from "components/Button";
import { FlexibleContentBlocksCta } from "graphql";

interface CTAProps extends FlexibleContentBlocksCta {
  className?: string;
}

const CTA = ({
  className,
  ctaTitle: title,
  link,
  backgroundColor,
  linkType,
}: CTAProps) => {
  let wrapperClass = clsx("py-8 md:py-12", `bg-${backgroundColor}`, {
    className: className,
  });

  return (
    <div className={wrapperClass}>
      <div className="container mx-auto max-w-5xl flex flex-col gap-6 items-center">
        {title && (
          <h2 className="text-2xl md:text-3xl text-center mb-4 text-white font-serif">
            {title}
          </h2>
        )}
        {link && linkType && (
          <Button
            // @ts-ignore
            variant={linkType[0] ?? "primary"}
            className="w-fit"
            href={link.url ?? "#"}
            target={link.target ?? "_self"}
          >
            {link.title}
          </Button>
        )}
      </div>
    </div>
  );
};

export default CTA;

CTA.fragments = {
  entry: gql`
    fragment CTAFragment on FlexibleContentBlocksCta {
      ctaTitle
      link {
        title
        url
        target
      }
      backgroundColor
      linkType
    }
  `,
};
