import { NavigationMenu } from "components/NavigationMenu";
import { useCycle, useMotionValue, useScroll, useSpring } from "framer-motion";
import { AcfLink, MediaItem, MenuItem } from "graphql";
import { useEffect, useRef, useState } from "react";
import flatListToHierarchical from "utilities/flatListToHierarchical";
import Logo from "public/logo.svg";
import Link from "next/link";
import clsx from "clsx";
export interface HeaderProps {
  menuItems: MenuItem[];
  logo: MediaItem;
  logoAlt: MediaItem;
  cta: AcfLink;
  cta2: AcfLink;
  turnOnAnnouncements: boolean;
  announcements: any[];
}

const Header = ({
  menuItems,
  logo,
  logoAlt,
  cta,
  cta2,
  turnOnAnnouncements,
  announcements,
}: HeaderProps) => {
  const [open, cycleOpen] = useCycle(false, true);
  const ref = useRef(null);
  const { scrollY } = useScroll();
  const [isScrolledPast, setIsScrolledPast] = useState(false);

  useEffect(() => {
    return scrollY.on("change", (y) => {
      // If latest is greater than height of header, set isScrolledPast to true
      if (y > 0) {
        setIsScrolledPast(true);
      } else {
        setIsScrolledPast(false);
      }
    });
  }, [scrollY]);

  // If mobile menu open disable body scroll
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  return (
    <>
      {turnOnAnnouncements && (
        <div className="">
          {announcements.map((bar, index) => {
            const bgColor = bar.backgroundColor;
            const content = bar.content;
            const ctaLink = bar.cta.url;
            const ctaTarget = bar.cta.target;

            return (
              <div
                key={`topBar-${index}`}
                className={`bg-${bgColor} text-white text-center p-2`}
              >
                <a href={ctaLink} target={ctaTarget}>
                  <div
                    className={`flex flex-col gap-4 font-sans`}
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </a>
              </div>
            );
          })}
        </div>
      )}
      <header
        className={`${
          isScrolledPast ? `shadow-md` : ``
        } body-font sticky top-0 z-50 max-h-fit bg-white font-sans text-gray-600`}
        ref={ref}
      >
        <div className="container mx-auto flex w-full  flex-row flex-wrap items-center justify-between px-4 py-2 md:py-4">
          <div className={`flex flex-row flex-wrap items-center justify-start`}>
            {/* Logo */}
            <Link
              href="/"
              className={clsx(
                `relative`,
                `transition-all duration-300 ease-in-out`,
                {
                  "max-w-[100px]": !isScrolledPast || open,
                  "max-w-[50px] md:max-w-[75px]": isScrolledPast,
                },
              )}
              title="Home"
            >
              <Logo className={`h-auto w-full`} />
            </Link>
          </div>
          {/* CTA */}
          <div
            className={`flex flex-col md:flex-row flex-wrap items-center justify-center`}
          >
            {/* Navigation Menu */}
            <NavigationMenu
              menuItems={flatListToHierarchical(menuItems)}
              isOpen={open}
              type="secondary"
              toggleOpen={() => cycleOpen()}
              cta={cta}
              cta2={{
                title: `Donate`,
                url: `/donate`,
              }}
            />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
