import { HtmlField } from "graphql";
import useGravityForm, {
  ACTION_TYPES,
  FieldValue,
  StringFieldValue,
} from "utilities/useGravityForm";

interface Props {
  field: HtmlField;
  formId: string;
}

const DEFAULT_VALUE = "";

export default function HTMLBlock({ field, formId }: Props) {
  const {
    databaseId: id,
    type,
    content,
    cssClass,
  } = field;
  const htmlId = `field_${formId}_${id}`;
  const { state, dispatch } = useGravityForm();
  const fieldValue = state.find(
    (fieldValue: FieldValue) => fieldValue.id === id,
  ) as StringFieldValue | undefined;
  const value = fieldValue?.value || DEFAULT_VALUE;

  return (
    <div
      className={`gfield flex flex-col font-sans gfield-${type} ${cssClass}`.trim()}
    >
        {content && (
            <div
                id={htmlId}
                className={`font-sans`}
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            />
        )}
    </div>
  );
}
