import { motion, useTransform, MotionValue, useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";

const fontSize = 30;
const padding = 15;
const height = fontSize + padding;

const Number = ({
  mv,
  number,
  place,
  isInView,
}: {
  mv: MotionValue;
  number: number;
  place: number;
  isInView: boolean;
}) => {
  let y = useTransform(mv, (latest) => {
    let placeValue = latest % 10;
    let offset = (10 + number - placeValue) % 10;
    let memo = offset * height;
    if (offset > 5) {
      memo -= 10 * height;
    }
    return memo;
  });

  useEffect(() => {
    if (isInView) {
      mv.set(number);
    }
  }, [isInView, mv, number]);

  return (
    <motion.span
      style={{ y }}
      className={`absolute inset-0 flex items-center justify-center font-accent font-bold text-secondary not-italic`}
    >
      {number}
    </motion.span>
  );
};

export default Number;
