import { Fragment } from "react";
import Digit from "./Digit";

const fontSize = 30;

const Counter = ({ value, isInView }: { value: number; isInView: boolean }) => {
  const numberDigits = String(value).length;
  const places = Array.from({ length: numberDigits }, (_, i) =>
    Math.pow(10, numberDigits - i - 1),
  );

  return (
    <div
      style={{ fontSize }}
      className="flex space-x-3 overflow-hidden rounded px-2 leading-none text-gray-900"
    >
      {places.map((place, index) => {
        return (
          <Fragment key={`counter-item-${index}-${value}`}>
            {places.length == 4 || places.length == 7
              ? index !== 0 &&
                index % 3 === 1 && (
                  <span
                    className={`pt-[9px] font-accent text-secondary`}
                    style={{
                      marginLeft: `6px !important`,
                      marginRight: `-3px !important`,
                    }}
                  >
                    ,
                  </span>
                )
              : places.length == 5 || places.length == 8
              ? index !== 0 &&
                index % 3 === 2 && (
                  <span
                    className={`pt-[9px] font-accent text-secondary`}
                    style={{
                      marginLeft: `6px !important`,
                      marginRight: `-3px !important`,
                    }}
                  >
                    ,
                  </span>
                )
              : index !== 0 &&
                index % 3 === 0 && (
                  <span
                    className={`pt-[9px] font-accent text-secondary`}
                    style={{
                      marginLeft: `6px !important`,
                      marginRight: `-3px !important`,
                    }}
                  >
                    ,
                  </span>
                )}
            {/* {index !== 0 && index % 3 === 0 && (
            <span
              className={`pt-[9px] font-accent text-secondary`}
              style={{
                marginLeft: `6px !important`,
                marginRight: `-3px !important`,
              }}
            >
              ,
            </span>
          )} */}
            <Digit
              key={index}
              place={place}
              value={value}
              isInView={isInView}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default Counter;
