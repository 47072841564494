import { gql } from "@apollo/client";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { FlexibleContentBlocksPhotoGallery, MediaItem } from "graphql";
import { useSnapCarousel } from "react-snap-carousel";
import clsx from "clsx";
import { FeaturedImage } from "components/FeaturedImage";
import JustRibbon from "public/assets/gallery-bg.svg";

interface PhotoGalleryProps extends FlexibleContentBlocksPhotoGallery {
  className?: string;
}

const PhotoGallery = ({
  className,
  images,
  galleryTitle: title,
}: PhotoGalleryProps) => {
  const {
    scrollRef,
    pages,
    activePageIndex,
    prev,
    next,
    goTo,
    snapPointIndexes,
  } = useSnapCarousel();
  const hasImages = images && images?.nodes?.length > 0;

  const imagesSplitIntoGroupsOfThree = images?.nodes.reduce(
    (acc, curr, index) => {
      const groupIndex = Math.floor(index / 3);
      const group = acc[groupIndex] || [];
      group.push(curr);
      acc[groupIndex] = group;
      return acc;
    },
    [] as any[],
  );

  return (
    <div className={clsx(`relative py-12 overflow-x-hidden`, className)}>
      <JustRibbon
        className={clsx(
          `absolute h-auto w-[110%]`,
          `-left-[5%]`,
          `top-1/3`,
          `md:top-1/4`,
          `lg:top-[10%]`,
          `xl:top-0`,
        )}
      />
      <div
        className={`flex z-10 flex-col container max-w-5xl mx-auto overflow-x-hidden gap-6 py-4 px-4 md:px-0 md:py-8 relative`}
      >
        {title && (
          <h2 className={`text-2xl font-sans uppercase text-center`}>
            {title}
          </h2>
        )}
        <div
          className={`flex flex-row overflow-x-scroll gap-4 no-scrollbar`}
          ref={scrollRef}
        >
          {imagesSplitIntoGroupsOfThree &&
            imagesSplitIntoGroupsOfThree.map((group, index) => {
              return (
                <div
                  key={index}
                  className={`flex flex-row justify-center items-center min-w-full gap-4`}
                >
                  {group.map((image: MediaItem) => {
                    const { id } = image;
                    return (
                      <FeaturedImage
                        className={`w-1/3`}
                        image={image}
                        key={id}
                      />
                    );
                  })}
                </div>
              );
            })}
        </div>
        <div className={`flex flex-row justify-center items-center gap-4`}>
          {imagesSplitIntoGroupsOfThree &&
            imagesSplitIntoGroupsOfThree.map((item, index) => {
              return (
                <button
                  key={`navigation-${item[0]?.id}`}
                  className={clsx(
                    `h-6 w-6 rounded-full border-primary border-[1px]`,
                    {
                      "bg-primary nav-button": activePageIndex === index,
                      "bg-white": activePageIndex !== index,
                    },
                  )}
                  onClick={() => goTo(index)}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;

PhotoGallery.fragments = {
  entry: gql`
    fragment PhotoGalleryFragment on FlexibleContentBlocksPhotoGallery {
      galleryTitle
      images(first: 60) {
        nodes {
          ...MediaItemFragment
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
