import { Main } from "features/Main"; // SEO
import {
  AcfLink,
  AcfGoogleMap,
  MediaItem,
  MenuItem,
  PostTypeSeo,
  RootQueryToMenuItemConnection,
} from "graphql";
import { SEO } from "features/SEO";

import { Header, Footer } from "components";
import { Lato, Roboto_Slab } from "next/font/google";
import localFont from "next/font/local";

const lato = Lato({
  subsets: ["latin"],
  weight: ["300", "400", "700", "900"],
  variable: "--font-sans",
});

const robotoSlab = Roboto_Slab({
  subsets: ["latin"],
  weight: ["300", "400", "700", "900"],
  variable: "--font-serif",
});

const relation = localFont({
  src: [
    {
      path: "../../pages/fonts/Relation.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../pages/fonts/Relation-Bold.woff2",
      weight: "700",
      style: "normal",
    },
  ],
  variable: "--font-accent",
});

export interface LayoutProps {
  children: React.ReactNode;
  headerMenuItems: RootQueryToMenuItemConnection;
  footerMenuItems: RootQueryToMenuItemConnection;
  title: string;
  description: string;
  logo: MediaItem;
  logoAlt: MediaItem;
  logoWhite: MediaItem;
  seo: PostTypeSeo;
  cta: AcfLink;
  phoneNumber: AcfLink;
  address: AcfGoogleMap;
  email: AcfLink;
  isAlt?: boolean;
  turnOnAnnouncements: boolean;
  announcements: any[];
}

const Layout = ({
  children,
  headerMenuItems,
  footerMenuItems,
  cta,
  title,
  description,
  seo,
  logo,
  logoAlt,
  logoWhite,
  phoneNumber,
  address,
  email,
  isAlt,
  turnOnAnnouncements,
  announcements,
}: LayoutProps) => {
  return (
    <>
      <SEO seo={seo} />
      <div
        className={`${lato.variable} ${robotoSlab.variable} ${relation.variable}`}
      >
        <Header
          menuItems={headerMenuItems.nodes}
          logo={logo}
          logoAlt={logoAlt}
          cta={cta}
          cta2={{
            title: `Donate`,
            url: `/donate`,
          }}
          turnOnAnnouncements={turnOnAnnouncements}
          announcements={announcements}
        />
        <Main className={`main`}>{children}</Main>
        <Footer address={address} isAlt={isAlt} />
      </div>
    </>
  );
};

export default Layout;
