import { gql } from "@apollo/client";
import { FlexibleContentBlocksHero } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import { Content } from "components/Content";
import { Button } from "components/Button";
import HeroRibbon from "public/assets/hero-ribbon.svg";
import HeroHands from "public/assets/hero-hands.svg";
import BG from "public/assets/bg.svg";
interface HeroProps extends FlexibleContentBlocksHero {
  className?: string;
}

const Hero = ({
  className,
  title,
  isH1,
  variant,
  ctas,
  video,
  image,
  content,
  icon,
}: HeroProps) => {
  let wrapperClass = clsx(
    "relative overflow-hidden max-h-[600px] lg:max-h-[1000px]",
    // Padding:
    `pt-12 md:pt-24 pb-[400px]`,
  );

  let innerWrapperClass = clsx(
    `items-center relative grid grid-cols-1 lg:grid-cols-2 container max-w-8xl mx-auto`,
  );

  let innerItemClass = clsx(
    `flex flex-col gap-4 z-10 relative min-h-full`,
    // Padding
    `pb-20 px-4 lg:pb-0`,
  );

  let handsWrapperClass = clsx(`flex flex-col z-10 relative px-4`);

  let handClasses = clsx(
    `absolute right-0 w-3/4`,
    // translate y 100% on mobile
    `translate-y-[-25%] lg:w-full lg:right-0 lg:top-0 animate-fade-in-up-hero`,
  );

  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      <div className={innerWrapperClass}>
        <div className={clsx(innerItemClass, `z-40`)}>
          {title ? <Title title={title} isH1={isH1} /> : null}
          <Content className={`max-w-sm font-sans text-lg`} content={content} />
          {ctas ? <CTAs ctas={ctas} /> : null}
        </div>
        <div className={handsWrapperClass}>
          <HeroHands className={handClasses} />
        </div>
      </div>
      <HeroRibbon
        className={clsx(
          `absolute -left-[95px] top-[40%]  min-w-[800px]`,
          `md:min-w-[110%]`,
          `lg:top-[20%]`,
          `xl:top-[15%]`,
          `2xl:top-0`,
        )}
      />
      <div className={`absolute left-0 right-0 top-[75%] z-20 lg:top-[60%]`}>
        <BG className={`w-full`} />
        <div className={`absolute left-0 right-0 top-1/2 h-1/2 bg-white`} />
      </div>
    </section>
  );
};

export default Hero;

const Title = ({ title, isH1 }: HeroProps) => {
  return (
    <div
      className={`hero-title-wrapper font-sans text-5xl font-bold`}
      dangerouslySetInnerHTML={{ __html: title ?? `` }}
    />
  );
};

const CTAs = ({ ctas }: FlexibleContentBlocksHero) => {
  return (
    <div className={`flex flex-col gap-4`}>
      {ctas &&
        ctas.map(
          (
            {
              // @ts-ignore
              link: { title, url, target },
              // @ts-ignore
              type,
            },
            i,
          ) => (
            <Button
              key={i}
              className={`w-fit`}
              variant={type[0] ?? `primary-outline`}
              href={url}
            >
              {title}
            </Button>
          ),
        )}
    </div>
  );
};

Hero.fragments = {
  entry: gql`
    fragment HeroFragment on FlexibleContentBlocksHero {
      __typename
      title
      isH1
      variant
      content
      ctas {
        link {
          title
          url
          target
        }
        type
      }
      image {
        node {
          ...MediaItemFragment
        }
      }
      video {
        node {
          mediaItemUrl
          mimeType
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
