import { gql } from "@apollo/client";
import clsx from "clsx";
import { FeaturedImage } from "components/FeaturedImage";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { FlexibleContentBlocksFeatures } from "graphql";

interface FeaturesProps extends FlexibleContentBlocksFeatures {
  className?: string;
}

const Features = ({
  className,
  featuresTitle: title,
  items,
  id,
}: FeaturesProps) => {
  return (
    <div
      className={clsx(
        className,
        `container mx-auto flex max-w-6xl flex-col gap-4 px-4 md:px-0 py-8 md:py-12 md:gap-8 md:pt-20 md:pb-4`,
      )}
      id={id ?? ``}
    >
      {title && (
        <h2 className={`text-center font-serif text-xl md:text-4xl`}>
          {title}
        </h2>
      )}
      <ul className={`grid grid-cols-1 md:grid-cols-3 gap-4`}>
        {items &&
          items.map(({ title, body, icon }: any, index) => (
            <li
              key={index}
              className={`flex flex-col items-center justify-start gap-2 px-4 md:gap-4 md:px-12`}
            >
              {icon && (
                <FeaturedImage
                  image={icon.node}
                  className={`h-24 w-24 animate-fade-in-up`}
                />
              )}
              {title && (
                <h3 className={`text-center font-serif text-2xl uppercase`}>
                  {title}
                </h3>
              )}
              {body && (
                <div
                  className={`text-center font-sans`}
                  dangerouslySetInnerHTML={{ __html: body }}
                />
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Features;

Features.fragments = {
  entry: gql`
    fragment FeaturesFragment on FlexibleContentBlocksFeatures {
      __typename
      featuresTitle
      id
      items {
        title
        body
        icon {
          node {
            ...MediaItemFragment
          }
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
